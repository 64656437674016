import styled from 'styled-components'

export const Container = styled.div`
    @media (max-width: 768px) {
        display: none;
    } 
    `
export const MobileContainer = styled.div`
    @media (min-width: 768px) {
        display: none;
    } 
`
export const BidCard = styled.div`
    width: 100%;
    border-radius: 5px;
    `;
export const BidDetails = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 10px;
`;

export const PriceDetails = styled.div`
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: end;
`;

export const SkuCode = styled.p`
    color: #92929C;
    margin: 0;

    `
export const ClientName = styled.p`
    font-weight: bold;
    margin-top: 10px;
`

export const CitytName = styled.p`
    margin: 0;
    font-size: 12px;
    `
    
    export const PriceName = styled.p`
    font-weight: bold;
    margin-top: 10px;
    `

export const TypeName = styled.p`
font-size: 12px;
    margin: 0;
`