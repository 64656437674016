import * as S from './styles'
import { useCompany } from "../../../core/hooks/useCompany";
import { Form as ComponentForm } from "antd"
import { Form } from './components/Form';
import { useState } from 'react';
import { formatPhoneInput } from '../../../core/utils/formatPhoneNumber';

export default function ClientPreRegistrationForm() {

    const { createPreRegister } = useCompany()
    const [form] = ComponentForm.useForm()
    const [showAddress, setShowAddress] = useState(false)


    const onCityChange = (value: any, option: any) => {
        form.setFieldValue('state', option.children[2])
    }

    const handleShowAddress = () => {
        setShowAddress(!showAddress)
    }

    const handlePhoneChange = (event: any) => {
        const { value } = event.target
        return formatPhoneInput(value)
    }

    return (
        <S.Container>
            <Form
                form={form}
                handlePhoneChange={handlePhoneChange}
                handleShowAddress={handleShowAddress}
                handleSubmit={(values) => createPreRegister({...values, city: values.city.split(" -")[0]})}
                onCityChange={onCityChange}
                showAddress={showAddress}
            />
        </S.Container>

    )
} 