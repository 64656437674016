export function getAccountType(typePerson: string) {
    switch (typePerson) {
        case 'BUYER':
            return 'COMPRADOR'
        case 'SELLER':
            return 'VENDEDOR'
        case 'BROKER':
            return 'CORRETOR'
        default:
            return 'ARMAZÉM'
    }
}

export function getColorType(typePerson: string) {
    switch (typePerson) {
        case 'BUYER':
            return 'green'
        case 'SELLER':
            return 'red'
        case 'BROKER':
            return 'purple'
        default:
            return 'gold'
    }
}