import React from 'react'
import Table from './components/Table'
import MobileTable from './components/MobileTable'
import { useCompany } from '../../../core/hooks/useCompany'
import usePageTitle from '../../../core/hooks/usePageTitle'

export default function ClientPreRegistrationTable() {
    usePageTitle('Listar Clientes')  

    const props = useCompany()

    return (
        <div>
            <Table
                {...props}
            />
            <MobileTable
                accounts={props.accounts?.content}
            />
        </div>
    )
}