import { createTheme } from "@mui/material/styles";

export const muiTheme = createTheme({
    palette:{
        primary:{
            dark: '#001500',
            main: '#006400',
            light: '#008000',
        }
    }
})