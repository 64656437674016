import { Tag } from "antd";
import { getAccountType, getColorType } from "../../../../core/utils/getAccountType";
import * as S from '../styles'
import { Company } from "uai-graos-sdk";

type MobileTableProps ={
    accounts? : Company.Company[]
}

export default function MobileTable (props: MobileTableProps) {
    return(
        <S.MobileContainer>
                    <hr />
                    {props.accounts?.map((item) => (
                        <>
                            <S.AccountCard key={item.id}>
                                <S.AccountDetails>
                                    <div>
                                        <S.Email>
                                            {item.phone}
                                        </S.Email>
                                        <S.ClientName>
                                            {item.name}
                                        </S.ClientName>
                                    </div>
                                    <div style={{ textAlign: 'end' }}>
                                        <S.CitytName>
                                            {item.city}
                                        </S.CitytName>
                                        <S.State>
                                            {item.state}
                                        </S.State>
                                    </div>
                                </S.AccountDetails>
                                <div style={{ marginBottom: 10, marginLeft: 5 }}>
                                    <Tag color={getColorType(item.type!)}>{getAccountType(item.type!)}</Tag>
                                </div>
                            </S.AccountCard>
                            <hr />
                        </>
                    ))}
                </S.MobileContainer>
    )
}