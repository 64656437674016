import React from 'react';
import { AppBar, Box, Collapse, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import { BorderAllOutlined, MenuOutlined, PersonOutline, ExpandLess, ControlPoint, LogoutOutlined, LocalOfferOutlined } from '@mui/icons-material';
import { ExclamationCircleFilled } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';

const drawerWidth = 240;

interface Props {
    window?: () => {
        document: {
            body: HTMLElement | null;
        };
    };
    readonly children: React.ReactNode;
}
const APP_BASE_URL = process.env.REACT_APP_PORTAL_BASE_URL;

export default function DeafultLayout(props: Props) {
    const layout = props.window;

    const { confirm } = Modal
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const navigation = useNavigate();

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const handlePageToggle = (url: string) => {
        navigation(url)
        handleDrawerToggle()
    }

    const handleLogout = async () => {
        confirm({
            title: 'Atenção!',
            icon: <ExclamationCircleFilled />,
            content: 'Você deseja mesmo sair do Portal?',
            okText: 'Sim',
            cancelText: 'Não',
            onOk() {
                return new Promise(() => {
                    window.localStorage.clear()
                    window.location.href = `${APP_BASE_URL}`
                })
            },
            onCancel() { },
        });
    }
    const items = [
        // {
        //     title: 'Home',
        //     url: '/dashboard',
        //     icon: HomeOutlined,
        //     onClick: () => {
        //         handlePageToggle('/dashboard')
        //     }
        // },
        {
            title: 'Clientes',
            url: 'clients-menu',
            icon: PersonOutline,
            subItems: [
                {
                    title: 'Pré-Cadastrar Cliente',
                    url: '/clients/pre-register',
                    icon: ControlPoint,
                    onClick: () => handlePageToggle('/clients/pre-register')

                },
                {
                    title: 'Listar Pré-Cadastros',
                    url: '/clients/pre-register-list',
                    icon: BorderAllOutlined,
                    onClick: () => handlePageToggle('/clients/pre-register-list')

                },
                {
                    title: 'Listar Clientes',
                    url: '/clients',
                    icon: BorderAllOutlined,
                    onClick: () => handlePageToggle('/clients')

                }
            ]
        },
        {
            title: 'Ofertas',
            url: 'bids-menu',
            icon: LocalOfferOutlined,
            subItems: [
                {
                    title: 'Listar Ofertas',
                    url: '/bids',
                    icon: BorderAllOutlined,
                    onClick: () => handlePageToggle('/bids')
                },
                {
                    title: 'Cadastrar Ofertas',
                    url: '/bids/register',
                    icon: ControlPoint,
                    onClick: () => handlePageToggle('/bids/register')
                }
            ]
        },
    ];



    const drawer = (
        <div>
            <Toolbar>
                <Typography variant="h6" noWrap component="div">
                    UAI Grãos
                </Typography>
            </Toolbar>
            <Divider />
            <List>
                {items.map((item) => (
                    <>
                        <ListItem key={item.url} disablePadding data-testid={item.url}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <item.icon htmlColor='#000'/>
                                </ListItemIcon>
                                <ListItemText primaryTypographyProps={{ color: '#000' }} primary={item.title} />
                                {item.subItems &&
                                    <ExpandLess htmlColor='#000' />
                                }
                            </ListItemButton>
                        </ListItem>
                        {item?.subItems?.map((subItem) => (
                            <Collapse in={true} timeout="auto" unmountOnExit key={subItem.url}>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }} onClick={subItem?.onClick} data-cy={subItem.url} data-testid={subItem.url}>
                                        <ListItemIcon>
                                            <subItem.icon htmlColor='#006400'/>
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ color: '#006400' }} primary={subItem.title} />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                        ))
                        }
                    </>
                ))}
                {/* Botão de sair da conta */}
                <ListItem onClick={handleLogout}>
                    <ListItemButton>
                        <ListItemButton>
                            <ListItemIcon>
                                <LogoutOutlined color='error' />
                            </ListItemIcon>
                            <ListItemText primaryTypographyProps={{ color: '#d32f2f' }} primary='Sair do Portal' />
                        </ListItemButton>
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );

    const container = layout !== undefined ? () => layout().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuOutlined />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Portal do Representante
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                overflow='auto'
                height='100vh'
                component="main"
                sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                {props.children}
            </Box>
        </Box>
    );
}
