import { useState } from 'react';
import { Moment } from 'moment';
import { Bid } from 'uai-graos-sdk';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import * as S from './styles'
import { filterPrice } from '../../../core/utils/filterPrice';
import { useBids } from '../../../core/hooks/useBids';
import {Form as AntdForm} from 'antd';
import Form from './components/Form';

type BidFormType = {
    deliveryMonth: Moment;
} & Omit<Bid.Bid, 'deliveryMonth'>;

interface BidFormProps {
    bid?: BidFormType;
    OnUpdate?: (bid: Bid.Bid) => any;
}




export default function BidForm(props: BidFormProps) {

    const { createBid } = useBids()

    const [accountId, setAccountId] = useState<string>('');
    const [modality, setModality] = useState('');
    const [showSide, setShowSide] = useState(props.bid?.side);
    const [acceptCounterBid, setAcceptCounBid] = useState<boolean | undefined>(props.bid?.acceptCounterBid);



    const [form] = AntdForm.useForm();

    const showDates = () => {
        if (modality === 'Futuro') {
            return true;
        } else {
            return false;
        }
    };

    const onCityChange = (value: any, option: any) => {
        form.setFieldValue('state', option.children[2])
    }

    const onAccountChange = (value: any, option: any) => [
        setAccountId(option.key)
    ]

    return (
        <S.Container>
            <Form 
            onFinish={
                (values: any) => createBid(accountId, {
                    ...values,
                    price: filterPrice(values.price)!,
                    bestPrice: filterPrice(values.bestPrice),
                    city: values.city.split(" -")[0],
                    bagsTotal: values.quantity
                })
            } 
            form={form}
            onAccountChange={onAccountChange}
            showSide={showSide}
            onShowSideChange={(e: any) => setShowSide(e)}
            onCityChange={onCityChange}
            modality={modality}
            showDates={showDates()}
            onChangeModality={(e: any) => setModality(e)}
            acceptCounterBid={acceptCounterBid}
            onChangeAcceptCounterBid={(e: CheckboxChangeEvent) => setAcceptCounBid(e.target.checked)}
            />
        </S.Container>
    );
}
