import React from "react";
import usePageTitle from "../../core/hooks/usePageTitle";



export default function Home() {

    usePageTitle('Dashboard')

    return (
        <div>todo: dashboard</div>
    )
} 