import { notification } from "antd"
import { Bid, BidService } from "uai-graos-sdk"
import { useNavigate } from "react-router-dom"

export const useBids = () => {

    const navigation = useNavigate()

    const createBid = async (accountId: string, body: Bid.BidInput) => {
        await BidService.createBid(accountId, body).then(() => {
            notification.success({
                message: 'Sucesso',
                description: 'Oferta criada com sucesso',
            });
            navigation('/bids')
        })
    } 
    return { createBid }
}