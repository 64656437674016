import {
    Table,
    Tag,
    Tooltip,
} from 'antd';
import moment from 'moment';
import { Account } from 'uai-graos-sdk';
import Avatar from 'antd/lib/avatar/avatar';
import { format as formatPhoneNumber } from 'telefone'
import Profile from '../../../../images/profile.png';
import { getAccountType, getColorType } from '../../../../core/utils/getAccountType';
import * as S from '../styles'

type AccountTableProps = {
    handlePaginationChange?: any
    accounts?: Account.PageAccount
    isAccountLoading?: boolean
    pagination?: Account.Query
}

export default function ComponentTable(props: AccountTableProps) {

    const columns = [
        {
            key: '1',
            title: 'Pessoa',
            dataIndex: 'username',
            width: 200,
            render: (username: string) => {
                return (
                    <Tooltip title={username}>
                        <p
                            style={{
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <Avatar
                                size={20}
                                style={{ marginRight: 10 }}
                                src={Profile}
                            />
                            {username}
                        </p>
                    </Tooltip>
                );
            },
        },
        {
            key: '2',
            title: 'E-Mail',
            dataIndex: 'email',
            width: 300,
            render: (email: any) => (
                <Tooltip title={email}>
                    <p
                        style={{
                            maxWidth: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {email}
                    </p>
                </Tooltip>
            ),
        },
        {
            key: '3',
            title: 'Telefone',
            dataIndex: 'phoneNumber',
            render: (phoneNumber: string) => formatPhoneNumber(phoneNumber) || phoneNumber
        },
        {
            key: '4',
            title: 'Perfil',
            dataIndex: 'typePerson',
            width: 100,
            render: (_: any, { typePerson }: any) => (
                <Tag
                    style={{ width: 90, textAlign: 'center' }}
                    color={getColorType(typePerson)}
                    key={typePerson}
                >
                    {getAccountType(typePerson)}
                </Tag>
            ),
        },
        {
            key: '5',
            title: 'Cidade',
            dataIndex: 'city',
        },
        {
            key: '6',
            title: 'Estado',
            dataIndex: 'state',
        },
        {
            key: '7',
            title: 'Data de criação',
            dataIndex: 'createdAt',
            render: (date: number) => {
                return (
                    <span>
                        {moment(date).utc().format('DD/MM/YYYY')}
                    </span>
                );
            }
        },
        {
            key: '9',
            title: 'Excluído?',
            dataIndex: 'virtual',
            width: 100,
            render: (virtual: boolean) => (
                <p>{virtual ? 'Sim' : 'Não'}</p>
            ),
        }
    ];


    return (
        <S.Container>
            <Table
                columns={columns}
                dataSource={props.accounts?.content}
                pagination={{
                    ...props.pagination,
                    total: props.accounts?.totalElements,
                    current: props.pagination?.page! + 1,
                    onChange: props.handlePaginationChange,
                    size: 'default',
                    showTotal: (total: number, range: any) =>
                        `${range[0]}-${range[1]} de ${total} registros`,
                }}
                loading={props.isAccountLoading}
            />
        </S.Container>
    );
}
