import { Col, Row, Form as ComponentForm, FormInstance, Select, Input, Divider, Button } from 'antd'
import { Company } from 'uai-graos-sdk'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import * as S from '../styles'
import CityInput from '../../../../components/CityInput'


type PreRegistrationFormProps = {
    handleSubmit: (values: Company.Input) => void
    form: FormInstance<Company.Input>
    handlePhoneChange: (event: any) => any
    onCityChange: (value: any, option: any) => void
    handleShowAddress: () => void
    showAddress: boolean
}

type typePerson = {
    name: string;
    value: string;
}

export const Form = ({ handleSubmit, form, handlePhoneChange, onCityChange, handleShowAddress, showAddress }: PreRegistrationFormProps) => {



    const types: typePerson[] = [
        { name: 'Comprador', value: 'BUYER' },
        { name: 'Vendedor', value: 'SELLER' },
        { name: 'Corretor', value: 'BROKER' },
        { name: 'Armazém', value: 'STORAGE' }
    ]

    return (
        <ComponentForm
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
        >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                    <ComponentForm.Item<Company.Input>
                        label="Nome do Cliente:"
                        name='name'
                        rules={[
                            { required: true, message: 'Por favor, insira o usuário.' }
                        ]}
                    >
                        <Input placeholder="Nome:" />
                    </ComponentForm.Item>
                </Col>
                <Col span={24}>
                    <ComponentForm.Item<Company.Input>
                        label="Telefone:"
                        name='phone'
                        rules={[
                            { required: true, message: 'Por favor, insira o telefone.' }
                        ]}
                        getValueFromEvent={handlePhoneChange}
                    >
                        <Input
                            onChange={handlePhoneChange}
                            placeholder="Ex.: (11) 4002-8922"
                        />
                    </ComponentForm.Item>
                </Col>
                <Col span={24}>
                    <CityInput
                        formProps={{
                            label: 'Cidade',
                            name: 'city'
                        }}
                        onCityChange={onCityChange}
                    />
                </Col>
                <Col span={24}>
                    <ComponentForm.Item<Company.Input>
                        label="Estado:"
                        name='state'
                    >
                        <Input placeholder="Estado:" disabled />
                    </ComponentForm.Item>
                </Col>
                {!showAddress ?
                    <S.AddressButton onClick={() => handleShowAddress()}>
                        <S.AddressButtonText>Mostrar mais informações de endreço:</S.AddressButtonText>
                        <DownOutlined />
                    </S.AddressButton>
                    :
                    <>
                        <S.AddressButton onClick={() => handleShowAddress()}>
                            <S.AddressButtonText>Mostrar menos informações de endreço:</S.AddressButtonText>
                            <UpOutlined />
                        </S.AddressButton>
                        <Divider />
                        <Col span={24}>
                            <ComponentForm.Item<Company.Input>
                                label="Endereço:"
                                name='address'
                            >
                                <Input placeholder="Endereço:" />
                            </ComponentForm.Item>
                        </Col>
                        <Col span={24}>
                            <ComponentForm.Item<Company.Input>
                                label="Número:"
                                name='addressNumber'
                            >
                                <Input placeholder="Número:" />
                            </ComponentForm.Item>
                        </Col>
                        <Col span={24}>
                            <ComponentForm.Item<Company.Input>
                                label="Bairro:"
                                name='neighborhood'
                            >
                                <Input placeholder="Bairro:" />
                            </ComponentForm.Item>
                        </Col>
                        <Col span={24}>
                            <ComponentForm.Item<Company.Input>
                                label="Complemento:"
                                name='addressComplement'
                            >
                                <Input placeholder="Complemento:" />
                            </ComponentForm.Item>
                        </Col>
                        <Divider />
                    </>
                }
                <Col span={24}>
                    <ComponentForm.Item<Company.Input>
                        label="Tipo:"
                        name='type'
                        rules={[
                            { required: true, message: 'Por favor, insira o tipo de perfil do cliente.' }
                        ]}
                    >
                        <Select
                            placeholder='Selecione o tipo de conta:'
                        >
                            {types.map((item) =>
                                <Select.Option key={item.value}>
                                    {item.name}
                                </Select.Option>
                            )}
                        </Select>
                    </ComponentForm.Item>
                </Col>
                <Col span={24}>
                    <ComponentForm.Item<Company.Input>
                        label="Tamanho da propriedade:"
                        name='area'
                    >
                        <Input placeholder="Tamanho da propriedade:" />
                    </ComponentForm.Item>
                </Col>
            </Row>
            <Row justify='end' >
                <Col span={24}>
                    <Button type='primary' htmlType='submit'>
                        Cadastrar
                    </Button>
                </Col>
            </Row>
        </ComponentForm>
    )
}