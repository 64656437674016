import { useAccounts } from '../../../core/hooks/useAccounts'
import MobileTable from './components/MobileTable'
import Table from './components/Table'
export default function ClientsTable() {

    const props = useAccounts()

    return (
        <div>
            <Table
                {...props}
            />
            <MobileTable
                accounts={props.accounts!}
            />
        </div>
    )
}