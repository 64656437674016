import { Button, Form, Input} from 'antd'
import * as S from './styles'
import logo from '../../images/greenLogo.png'
import AuthService from '../../services/Auth.Service';
import usePageTitle from '../../core/hooks/usePageTitle';


export default function Login(){
    usePageTitle('Login')
    const onFinish = (values: any) => {
        const config  = {
            login : values.email,
            password: values.password
          }
          AuthService.getFirstAccessTokens(config)
    };
    return (
        <S.CenteredContainer>
            <S.LoginContainer>
                <S.LogoContainer>
                    <S.Logo alt='UAI Grãos' src={logo} />
                </S.LogoContainer>
                <Form
                    name="login-form"
                    initialValues={{ remember: true }}
                    layout='vertical'
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="E-mail:"
                        name="email"
                        rules={[{ required: true, message: 'Por favor insira o e-mail!' }]}
                    >
                        <Input 
                        
                        />
                    </Form.Item>

                    <Form.Item
                        label="Senha:"
                        name="password"
                        rules={[{ required: true, message: 'Por favor insira a senha!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <S.ButtonContainer>
                        <Button type="primary" htmlType="submit" size='large'>
                            Entrar
                        </Button>
                    </S.ButtonContainer>

                </Form>
            </S.LoginContainer>
        </S.CenteredContainer>

    )
}