import { useEffect } from 'react'

export default function usePageTitle(title: string) {
    const BASE_TITLE = 'Portal do Representante'

    useEffect(() => {
        document.title = `${title} - ${BASE_TITLE}`
    }, []) //eslint-disable-line

}

