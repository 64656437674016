import { Avatar, Space, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import { UserAddOutlined } from "@ant-design/icons";
import * as S from './../styles'
import profile from '../../../../images/profile.png'
import { formatPhoneTable } from "../../../../core/utils/formatPhoneNumber";
import { Company } from "uai-graos-sdk";
import { getAccountType, getColorType } from "../../../../core/utils/getAccountType";
import { ColumnsType } from "antd/es/table";

type TableProps = {
    accounts?: Company.Page
    pagination: any
    handlePaginationChange: any
    isLoading: any
    createAccountByCompany: any
}

export default function ComponentTable(props: TableProps){  
        const columns : ColumnsType<Company.Company>= [
            {
                key: '1',
                title: 'Pessoa',
                dataIndex: 'name',
                width: 200,
                render: (username: string) => {
                    return (
                        <Tooltip title={username}>
                            <S.TableName>
                                <Avatar
                                    size={20}
                                    style={{ marginRight: 10 }}
                                    src={profile}
                                />
                                {username}
                            </S.TableName>
                        </Tooltip>
                    );
                },
            },
            {
                key: '2',
                title: 'Telefone',
                dataIndex: 'phone',
                width: 300,
                render: (phone: any) => (
                    <Tooltip title={formatPhoneTable(phone)}>
                        {formatPhoneTable(phone)}
                    </Tooltip>
                ),
            },
            {
                key: '3',
                title: 'Perfil (Tipo)',
                dataIndex: 'type',
                width: 100,
                render: (_: any, { type }: any) => (
                    <Tag
                        style={{ width: 90, textAlign: 'center' }}
                        color={getColorType(type)}
                        key={type}
                    >
                        {getAccountType(type)}
                    </Tag>
                ),
            },
            {
                key: '4',
                title: 'Cidade',
                dataIndex: 'city',
            },
            {
                key: '5',
                title: 'Estado',
                dataIndex: 'state',
                render: (state: string) => {
                    if (!state) {
                        return 'Não Informado'
                    }
                    return state
                }
            },
            {
                key: '6',
                title: 'Data de criação',
                dataIndex: 'createdAt',
                render: (date: number) => {
                    return (
                        <span>
                            {moment(date).utc().format('DD/MM/YYYY')}
                        </span>
                    );
                },
            },
            {
                key: '6',
                title: 'Data de criação',
                dataIndex: 'createdAt',
                render: (date: number) => {
                    return (
                        <span>
                            {moment(date).utc().format('DD/MM/YYYY')}
                        </span>
                    );
                },
            },
            {
                key: '7',
                title: 'Ações',
                dataIndex: 'id',
                fixed: 'right',
                render: (id: any, record: Company.Company) => (
                    <Space size='middle'>
                        <Tooltip title='Adicionar usuário'>
                            <UserAddOutlined onClick={() => props.createAccountByCompany(id, record)}/>
                        </Tooltip>
                    </Space>
                ),
            },
        ];
    
        return (
                <S.Container>
                    <Table
                        columns={columns}
                        dataSource={props.accounts?.content}
                        loading={props.isLoading}
                        pagination={{
                            ...props.pagination,
                            total: props.accounts?.totalElements,
                            size: 'default',
                            current: props.pagination.page! + 1,
                            onChange: props.handlePaginationChange,
                            showTotal: (total: number, range: any) =>
                                `${range[0]}-${range[1]} de ${total} registros`,
                        }}
                    />
                </S.Container>
        )
}