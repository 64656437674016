import { Form, Select, Spin } from 'antd'
import { Rule } from 'antd/es/form'
import { DefaultOptionType } from 'antd/es/select'
import { Account } from 'uai-graos-sdk'
import { useAccounts } from '../../core/hooks/useAccounts'


interface AccountSearchInputProps {
    onAccountChange?: ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void) | undefined
    formProps?: {
        rules?: Rule[];
        label?: string;
        name?: string;
    }
}

export default function AccountSearchInput({ onAccountChange, formProps }: AccountSearchInputProps) {

    const { searchedAccounts, onAccountSearch, isAccountLoading } = useAccounts()


    return (
        <Form.Item
            {...formProps}
        >
            <Select
                onChange={onAccountChange}
                onSearch={onAccountSearch}
                placeholder='Pesquisa por nome:'
                showSearch
                notFoundContent={isAccountLoading ? <Spin size="small" /> : null}
            >
                {searchedAccounts?.content?.map((item: Account.Account) => (
                    <Select.Option
                        key={item.id}
                        value={item.username}
                    >
                        {`${item.username} (${item.email})`}
                    </Select.Option>
                ))}
                {
                    searchedAccounts?.totalElements && searchedAccounts?.totalElements > 5 ?
                        <Select.Option disabled>
                            Mais {(searchedAccounts?.totalElements) - 5} contas cadastradas
                        </Select.Option>
                        : null
                }
            </Select>
        </Form.Item>
    )


} 