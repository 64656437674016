import React from 'react'
import * as S from '../styles'
import { getAccountType, getColorType } from '../../../../core/utils/getAccountType'
import { Tag } from 'antd'
import { Account } from 'uai-graos-sdk'

type AccountMobileTableProps = {
    accounts: Account.PageAccount
}

function MobileTable(props: AccountMobileTableProps) {
    return (
        <S.MobileContainer>
            <hr />
            {props.accounts?.content?.map((item) => (
                <>
                    <S.AccountCard key={item.id}>
                        <S.AccountDetails>
                            <div>
                                <S.Email>
                                    {item.phoneNumber}
                                </S.Email>
                                <S.ClientName>
                                    {item.username}
                                </S.ClientName>
                            </div>
                            <div style={{ textAlign: 'end' }}>
                                <S.CitytName>
                                    {item.city}
                                </S.CitytName>
                                <S.State>
                                    {item.state}
                                </S.State>
                            </div>
                        </S.AccountDetails>
                        <div style={{ marginBottom: 10, marginLeft: 5 }}>
                            <Tag color={getColorType(item.typePerson!)}>{getAccountType(item.typePerson!)}</Tag>
                        </div>
                    </S.AccountCard>
                    <hr />
                </>
            ))}
        </S.MobileContainer>
    )
}

export default MobileTable