import { useEffect,useState } from "react"
import { Account, MerchantResponsibleService } from "uai-graos-sdk"
import AuthService from "../../services/Auth.Service"

export const useAccounts = () => {

    const accountId = AuthService.getAccount()

    const [isAccountLoading, setIsAccountLoading] = useState<boolean>()
    const [accounts, setAccounts] = useState<Account.PageAccount>()
    const [searchedAccounts, setSearchedAccounts] = useState<Account.PageAccount>()


    const [searchedPagination, setSearchPagination] = useState<Account.Query>({
        page: 0,
        size: 5,
        sort: ["updatedAt", "DESC"],
    })
    const [pagination, setPagination] = useState<Account.Query>({
        page: 0,
        size: 10,
        sort: ["updatedAt", "DESC"],
    })

    useEffect(() => {
        getAccountsFromMerchant(pagination)
        searchAccountsFromMerchant(searchedPagination)
    }, [pagination, searchedPagination])

    const handlePaginationChange = async (
        page: any,
        pageSize: any
    ) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: page - 1,
            current: page - 1,
            pageSize: pageSize,
        }));
    };


    const onAccountSearch = async (value: string) => {
        setSearchPagination({ ...searchedPagination, username: value })
    }

    const getAccountsFromMerchant = async (pagination: Account.Query) => {
        setIsAccountLoading(true)
        await MerchantResponsibleService.getAccounts(accountId!, pagination).then(setAccounts)
        setIsAccountLoading(false)
    }

    const searchAccountsFromMerchant = async (pagination: Account.Query) => {
        setIsAccountLoading(true)
        await MerchantResponsibleService.getAccounts(accountId!, pagination).then(setSearchedAccounts)
        setIsAccountLoading(false)
    }

    return { accounts, onAccountSearch, isAccountLoading, handlePaginationChange, pagination, searchedAccounts }
}