import { notification, Space, Table, Tag, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { PoweroffOutlined, EditOutlined } from '@ant-design/icons';
import moment from "moment";
import { Bid, BidService, MerchantResponsibleService } from "uai-graos-sdk";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import * as S from './styles'
import usePageTitle from "../../../core/hooks/usePageTitle";
import AuthService from "../../../services/Auth.Service";

export default function BidsTable() {

    usePageTitle('Listar Ofertas')

    const [bids, setBids] = useState<Bid.Bid[]>()
    const [pagination, setPagination] = useState<Bid.Query>({
        page: 0,
        size: 10,
        side: 'ALL',
        sort: ["updatedAt", "DESC"], 
        product: undefined,
        username: ''
      });

    const accountId = AuthService.getAccount()

    useEffect(() => {
        responseApi(pagination)
    }, [])

    function responseApi(
        query: Bid.Query
      ) {
        MerchantResponsibleService.getBids(accountId!, { size: 10 })
        .then((resp) =>{
            const { content, totalElements } = resp;
            setBids(content);
            setPagination((prevPagination) => ({
              ...prevPagination,
              total: totalElements,
              showTotal: (total: number, range: any) =>
                `${range[0]}-${range[1]} de ${total} registros`,
              page: query.page,
            }));
          })
      }

    async function handleInativeBid(record: Bid.Bid) {
        if (!record.virtual) {
          await BidService.inactiveBid(record.id!)
            .then(() => {
              responseApi(pagination)
              notification.success({
                message: 'Sucesso!',
                description: 'A oferta foi desativada',
              });
            })
            .catch(console.log)
        } else {
          await BidService.restoreBid(record.id!)
            .then(() => {
              responseApi(pagination)
              notification.success({
                message: 'Sucesso!',
                description: 'A oferta foi ativada',
              });
            })
            .catch(console.log)
        }
      }

    const columns: ColumnsType<Bid.Bid> = [
        {
            key: '1',
            title: 'Código',
            dataIndex: 'sku',
        },
        {
            key: '2',
            title: 'Cliente',
            dataIndex: 'username',
        },
        {
            key: '3',
            title: 'Produto',
            dataIndex: 'product',
        },
        {
            key: '4',
            title: 'Tipo',
            dataIndex: 'side',
            render: (_: any, { side }: any) => (
                <Tag
                    color={side === 'V' ? 'red' : 'blue'}
                    key={side}
                >
                    {side === 'C' ? 'COMPRA' : 'VENDA'}
                </Tag>
            ),
        },
        {
            key: '5',
            title: 'Estado',
            dataIndex: 'address',
            render: (address: any) => address?.state
        },
        {
            key: '6',
            title: 'Cidade',
            dataIndex: 'address',
            render: (address: any) => address?.city
        },
        {
            key: '7',
            title: 'Preço',
            dataIndex: 'price',
            render: (price: number) => (
                <div style={{ minWidth: '65px' }}>
                    R$ {price.toFixed(2).replace('.', ',')}
                </div>
            )
        },
        {
            key: '8',
            title: 'Quantidade',
            dataIndex: 'quantity',
        },
        {
            key: '9',
            title: 'Data de Criação',
            dataIndex: 'createdAt',
            render: (date: number) => {
                return (
                    <span>
                        {moment(date).utc().format('DD/MM/YYYY')}
                    </span>
                );
            },
        },
        {
            key: '10',
            title: 'Tipo de Pagamento',
            dataIndex: 'payment',
            render: (payment: string) => (
                payment ?? 'N/A'
            )
        },
        {
            key: '10',
            title: 'Ativo?',
            dataIndex: 'virtual',
            render: (status: boolean) => (
                !status ? 'Sim' : 'Não'
            )
        },
        // {
        //     title: 'Ações',
        //     key: '11',
        //     dataIndex: 'id',
        //     render: (id: string, record: any) => (
        //       <Space size='middle'>
        //         {/* <Tooltip title='Editar oferta'>
        //           <Link to={`bids/edit/${id}`}>
        //             <EditOutlined />
        //           </Link>
        //         </Tooltip> */}
        //         <Tooltip title={`${record.virtual ? 'Ativar' : 'Desativar'} oferta`}>
        //           <PoweroffOutlined style={{ color: `${record.virtual ? '#ff7875' : '#4096ff'}` }} onClick={() => handleInativeBid(record)} />
        //         </Tooltip>
        //       </Space>
        //     ),
        //   },
    ];

    function filterType(type: string) {
        switch (type) {
            case 'A prazo máximo de 30 dias':
                return 'Prazo de 30 dias'
            default:
                return type
        }
    }


    return (
        <div>
            <S.Container>
                <Table
                    dataSource={bids}
                    rowKey={'id'}
                    columns={columns}
                />
            </S.Container>



            <S.MobileContainer>
                <hr />
                {bids?.map((item) => (
                    <>
                        <S.BidCard key={item.id}>
                            <S.BidDetails>
                                <div>
                                    <S.SkuCode>
                                        #{item.sku}
                                    </S.SkuCode>
                                    <S.ClientName>
                                        {item.username}
                                    </S.ClientName>
                                </div>
                                <div style={{ textAlign: 'end' }}>
                                    <S.SkuCode>
                                        {item.address?.city}
                                    </S.SkuCode>
                                    <S.ClientName>
                                        R$ {item.price}
                                    </S.ClientName>
                                </div>
                            </S.BidDetails>
                            <div style={{ marginBottom: 10, marginLeft: 5 }}>
                                <Tag color="gold">{item.product}</Tag><Tag color="green">{item.side === 'C' ? 'Compra' : 'Venda'}</Tag><Tag color="blue">{filterType(item.payment!)}</Tag>
                            </div>
                        </S.BidCard>
                        <hr />
                    </>
                ))}
            </S.MobileContainer>

        </div>
    )
}