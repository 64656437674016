import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { CustomError } from "uai-graos-sdk";
import Login from "./pages/Login";
import Home from "./pages/Home";
import BidsTable from "./pages/Bids/BidsTable";
import ClientsTable from "./pages/Clients/ClientsTable";
import DefaultLayout from "./layouts/DefaultLayout";
import ClientPreRegistrationForm from "./pages/Clients/ClientPreRegistrationForm";
import { notification } from "antd";
import BidForm from "./pages/Bids/BidForm";
import ClientPreRegistrationTable from "./pages/Clients/ClientPreRegistratrionTable";

const AppRoutes = () => {

  const indexUrl = process.env.REACT_APP_PORTAL_BASE_URL

  useEffect(() => {
    window.onunhandledrejection = ({ reason }) => {
      if (reason instanceof CustomError) {
        if (reason.message) {
          notification.error({
            message: "Erro!",
            description: reason.message,
          })
          return
        }
        notification.error({
          message: 'Houve um erro durante a ação',
        });
        return
      }
      notification.error({
        message: reason,
      });
    };

    return () => {
      window.onunhandledrejection = null;
    };
  }, [])

  return (

    <>
      {
        window.location.href === `${indexUrl}` ?
          <Routes>
            <Route path="/" element={<Login />} />
          </Routes>
          :
          <DefaultLayout>
            <Routes>
              <Route index path="/dashboard" element={<Home />} />
              <Route index path="bids" element={<BidsTable />} />
              <Route index path="bids/register" element={<BidForm />} />
              <Route index path="clients" element={<ClientsTable />} />
              <Route index path="clients/pre-register-list" element={<ClientPreRegistrationTable />} />
              <Route index path="clients/pre-register" element={<ClientPreRegistrationForm />} />
            </Routes>
          </DefaultLayout>
      }
    </>

  )
};

export default AppRoutes;
