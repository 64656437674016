import styled from "styled-components";
import { Input } from "antd";

export const Container = styled.div`
 margin: 20px;
`

export const FormInput = styled(Input)`
    width: 100%;
`

export const AddressButton = styled.div`
     display: flex;
     align-items: center;
     justify-content: center;
     width: 100%;
     text-align: center;
     flex-direction: row;
    :hover{
        cursor: pointer;
    }
`
export const AddressButtonText = styled.p`
    margin-right: 10px;
`