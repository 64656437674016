import { Button } from "antd";
import styled from "styled-components";


export const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const LoginContainer = styled.div`
  width: 500px;
  margin: 0 auto;
  padding: 20px;
  @media (max-width: 768px) {
    width: 80%;
  }
  text-align: center;
`

export const LogoContainer = styled.div`
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
`

export const Logo = styled.img<{ src: string, alt: string }>`
    max-width: 50%;
    height: auto;
`

export const SubmitButton = styled(Button)`
    background-color: #376e37;
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    .ant-btn-primary {
        background-color: #376e37;
    }
    :where(.css-dev-only-do-not-override-1k979oh).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
        color: #fff;
        background: #28a745;
}
`