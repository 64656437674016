import React from 'react'
import { Button, Checkbox, Col, Form as ComponentForm, DatePicker, Input, Row, Select, Tabs } from 'antd'
import AccountSearchInput from '../../../../components/AccountSearchInput'
import CityInput from '../../../../components/CityInput';
import CashInput from '../../../../components/CashInput';


type FormProps = {
    onFinish : any,
    form: any,
    onAccountChange: any;
    showSide: any;
    onShowSideChange: any;
    onCityChange: any;
    modality: any;
    onChangeModality: any;
    showDates: any;
    onChangeAcceptCounterBid: any;
    acceptCounterBid: any;
}

export default function Form (props: FormProps) {
    const { TabPane } = Tabs;

    const { TextArea } = Input;

    const freights = [
        {
            id: '1',
            type: 'CIF - Por conta do Vendedor',
        },
        {
            id: '2',
            type: 'FOB - Por conta do Comprador',
        },
        {
            id: '3',
            type: 'À combinar - CIF ou FOB',
        },
    ];

    const storageOptions = [
        { id: '1', type: 'Silo Metálico' },
        { id: '2', type: 'Silo Bolsa' },
        { id: '3', type: 'Boca de Colheitadeira' },
        { id: '4', type: 'Armazém' },
        { id: '5', type: 'Outros Armazenamentos' },
    ];

    const showModalities = [
        {
            id: '1',
            type: 'Disponível',
        },
        {
            id: '2',
            type: 'Futuro',
        },
    ];

    const products = [
        { id: '1', type: 'Milho' },
        { id: '2', type: 'Soja' },
        { id: '3', type: 'Sorgo' },
    ];

    const side = [
        { id: '1', type: 'C' },
        { id: '2', type: 'V' },
    ];

    const payments = [
        {
            id: '1',
            type: 'No carregamento',
        },
        {
            id: '2',
            type: 'No recebimento',
        },

        {
            id: '3',
            type: 'A prazo máximo de 30 dias',
        },
        {
            id: '4',
            type: 'À combinar',
        },
    ];

    return (<ComponentForm
        autoComplete={'off'}
        layout={'vertical'}
        onFinish={props.onFinish}
        form={props.form}
    >
        <Row gutter={24}>
            <Col xs={24} lg={8}>
                <AccountSearchInput
                    formProps={{
                        label: 'Usuário:',
                        name: 'username',
                        rules: [
                            { required: true, message: 'Este campo não pode estar em branco!' }
                        ]
                    }}
                    onAccountChange={props.onAccountChange}
                />
            </Col>

            <Col xs={24} lg={8}>
                <ComponentForm.Item
                    label='Produto'
                    name='product'
                    rules={[
                        {
                            required: true,
                            message:
                                'O campo do produto é obrigatório!',
                        },
                    ]}
                >
                    <Select
                        showSearch
                    >
                        {products.map((product: any) => (
                            <Select.Option
                                key={product.id}
                                value={product.type}
                            >
                                {product.type}
                            </Select.Option>
                        ))}
                    </Select>
                </ComponentForm.Item>
            </Col>

            <Col xs={24} lg={8}>
                <ComponentForm.Item
                    label='Tipo de negociação'
                    name='side'
                    rules={[
                        {
                            required: true,
                            message:
                                'O campo do tipo de negociação é obrigatório!',
                        },
                    ]}
                >
                    <Select
                        value={props.showSide}
                        onChange={props.onShowSideChange}
                        showSearch
                    >
                        {side.map((product: any) => (
                            <Select.Option
                                key={product.id}
                                value={product.type}
                            >
                                {product.type === 'V'
                                    ? 'Venda'
                                    : 'Compra'}
                            </Select.Option>
                        ))}
                    </Select>
                </ComponentForm.Item>
            </Col>
            <Col xs={24} lg={8}>
                <CityInput
                    onCityChange={props.onCityChange}
                    formProps={{
                        label: 'Cidade',
                        name: 'city',
                        rules: [
                            { required: true, message: 'O campo de cidade é obrigatório!' }
                        ]
                    }}
                />
            </Col>
            <Col xs={24} lg={8}>
                <ComponentForm.Item
                    label='Estado'
                    name='state'
                >
                    <Input disabled placeholder={'Ex.: MG'} />
                </ComponentForm.Item>
            </Col>
            <Col xs={24} lg={8}>
                <ComponentForm.Item
                    label='CEP'
                    name='cep'
                >
                    <Input
                        placeholder={'Ex.: 37800-500'}
                    />
                </ComponentForm.Item>
            </Col>
            <Col xs={24} lg={8}>
                <ComponentForm.Item
                    label='Quantidade mínima por negociação (saca)'
                    name='bagsMinimumApplication'
                    initialValue={'700'}
                    rules={[
                        {
                            required: true,
                            message:
                                'Informe a quantidade mínima por negociação!',
                        },
                    ]}
                >
                    <Input />
                </ComponentForm.Item>
            </Col>
            <Col xs={24} lg={8}>
            <CashInput 
                name='price'
                label='Preço da Oferta (saca)'
                rules={[{required: true}]}
            
            />
            </Col>
            <Col xs={24} lg={8}>
                <ComponentForm.Item
                    label='Forma de pagamento'
                    name='payment'
                    initialValue={'No carregamento'}
                    rules={[
                        {
                            required: true,
                            message:
                                'O campo de  forma de pagamento é obrigatório!',
                        },
                    ]}
                >
                    <Select showSearch>
                        {payments.map((payments: any) => (
                            <Select.Option
                                key={payments.id}
                                value={payments.type}
                            >
                                {payments.type}
                            </Select.Option>
                        ))}
                    </Select>
                </ComponentForm.Item>
            </Col>
            <Col xs={24} lg={8}>
                <ComponentForm.Item
                    label='Forma de Entrega'
                    name='freight'
                    initialValue={'FOB - Por conta do Comprador'}
                    rules={[
                        {
                            required: true,
                            message:
                                'O campo de forma de entrega é obrigatório!',
                        },
                    ]}
                >
                    <Select showSearch>
                        {freights.map((freights: any) => (
                            <Select.Option
                                key={freights.id}
                                value={freights.type}
                            >
                                {freights.type}
                            </Select.Option>
                        ))}
                    </Select>
                </ComponentForm.Item>
            </Col>

            <Col xs={24} lg={8}>
                <ComponentForm.Item
                    label='Quantidade'
                    name='quantity'
                    rules={[
                        {
                            required: true,
                            message:
                                'O campo de quantidade é obrigatório!',
                        },
                    ]}
                >
                    <Input />
                </ComponentForm.Item>
            </Col>


            <Col xs={24} lg={8}>
                <ComponentForm.Item
                    label='Modalidade'
                    name='modality'
                    initialValue='Disponível'
                    rules={[
                        {
                            required: true,
                            message:
                                'O campo de modadidade é obrigatório!',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        value={props.modality}
                        onChange={props.onChangeModality}
                    >
                        {showModalities.map((modality: any) => (
                            <Select.Option
                                key={modality.id}
                                value={modality.type}
                            >
                                {modality.type}
                            </Select.Option>
                        ))}
                    </Select>
                </ComponentForm.Item>
            </Col>
            <Col xs={24} lg={8}>
                <ComponentForm.Item
                    label='Data de entrega (estimada)'
                    name='deliveryMonth'
                    rules={[
                        {
                            required: props.showDates,
                            message:
                                'Por favor selecione a data de entrega!',
                        },
                    ]}
                >
                    <DatePicker
                        disabled={!props.showDates}
                        style={{ width: '100%' }}
                        format={'DD/MM/YYYY'}
                    />
                </ComponentForm.Item>
            </Col>

            {props.showSide === 'V' ? (
                <>
                    <Col xs={24} lg={8}>
                        <ComponentForm.Item
                            label='Armazenamento'
                            name='storage'
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'O campo de armazenamento é obrigatório!',
                                },
                            ]}
                        >
                            <Select showSearch>
                                {storageOptions.map((storage: any) => (
                                    <Select.Option
                                        key={storage.id}
                                        value={storage.type}
                                    >
                                        {storage.type}
                                    </Select.Option>
                                ))}
                            </Select>
                        </ComponentForm.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <ComponentForm.Item
                            label='Observação'
                            name='obs'
                        >
                            <TextArea
                                showCount
                                maxLength={350}
                                style={{ height: 120, marginBottom: 24 }}
                                placeholder='Insira uma observação'
                            />
                        </ComponentForm.Item>
                    </Col>
                </>
            ) : null}
        </Row>
        <Tabs defaultActiveKey={'personal'}>
            <TabPane
                key={'personal'}
                tab={'Informações de Contraproposta'}
            >
                <Row gutter={24}>
                    <Col xs={24} lg={8}>
                        <ComponentForm.Item
                            name='acceptCounterBid'
                            valuePropName='checked'
                        >
                            <Checkbox
                                onChange={props.onChangeAcceptCounterBid}
                            >
                                Aceita contraproposta
                            </Checkbox>
                        </ComponentForm.Item>
                    </Col>

                    {props.acceptCounterBid && (
                        <Col xs={24} lg={8}>
                            <ComponentForm.Item
                                name='bestPrice'
                            >
                                <Input
                                    name='bestPrice'
                                //   label='Preço min/max da Contraproposta (saca)'
                                />
                            </ComponentForm.Item>
                        </Col>
                    )}

                </Row>
            </TabPane>
        </Tabs>
        <Row justify='end'>
            <Col>
                <ComponentForm.Item>
                    <Button style={{ marginTop: 16 }} type='primary' size='large' htmlType='submit'>
                        Criar Oferta
                    </Button>
                </ComponentForm.Item>
            </Col>
        </Row>
    </ComponentForm>
    )
}