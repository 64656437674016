import { NumericFormat } from 'react-number-format';
import { Form } from 'antd';
import './CashInput.styles.css'
import { Rule } from 'antd/es/form';

type CashInputProps = {
    value?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    disabled?: boolean;
    name?: string;
    label?: string;
    rules?: Rule[];
}


export default function CashInput(props: CashInputProps) {
  return (
    <Form.Item
      label={props.label}
      name={props.name}
      rules={props.rules}
    >
      <NumericFormat
        value={props.value}
        onChange={props.onChange}
        thousandSeparator={true}
        prefix={'R$ '}
        decimalScale={2}
        fixedDecimalScale={true}
        allowNegative={false}
        className='cash-input'
        disabled={props.disabled}
        style={{width: '100%'}}
        />
    </Form.Item>
  );
}