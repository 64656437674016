import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes';
import { ThemeProvider } from '@mui/material/styles';
import { muiTheme } from './core/themes/DefaultTheme';

function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <React.StrictMode>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </React.StrictMode>
    </ThemeProvider>

  );
}

export default App;
