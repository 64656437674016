import { notification } from 'antd';
import axios from 'axios';
import { hash, compare } from 'bcryptjs'

const APP_BASE_URL = process.env.REACT_APP_PORTAL_BASE_URL;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const authServer = axios.create({
  baseURL: API_BASE_URL,
});

export interface OAuthAuthorizationTokenResponse {
  access_token: string;
  token_type: 'bearer' | string;
  expires_in: number;
  accountId: string;
  rule: string;
}

export default class AuthService {


  public static async getFirstAccessTokens(config: {
    login: string,
    password: string
  }) {
    return authServer.post<OAuthAuthorizationTokenResponse>('/auth/login', config, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(async (resp) => {
      this.setRules(resp.data.rule)
      if (resp.data.rule !== 'MERCHANT_RESPONSIBLE') {
        window.localStorage.clear()
        notification.error({
          message: 'Você não tem permissão para acessar o sistema'
        })
        return;
      }
      this.setAccessToken(resp.data.access_token)
      this.setAccount(resp.data.accountId)
      window.location.href = `${APP_BASE_URL}clients/pre-register`

    }).catch(() => {
      notification.error({
        message: 'Usuário/Senha Incorretos!'
      })
    });
  }

  public static async imperativelySendToLoginScreen() {
    window.location.href = `${APP_BASE_URL}`;
  }

  public static getAccessToken() {
    return window.localStorage.getItem('access_token');
  }
  public static setAccessToken(token: string) {
    return window.localStorage.setItem('access_token', token);
  }

  public static getAccount() {
    return window.localStorage.getItem('account');
  }
  public static setAccount(id: string) {
    return window.localStorage.setItem('account', id);
  }

  public static async setRules(rule: string) {
    if (!rule) return 'No permissions'
    const accountRules =  await hash(rule, 10)
    const rulesPromise = await Promise.all(accountRules)
    return window.localStorage.setItem('rules', rulesPromise.toString())
  }

  public static async getRules() {
    const encryptedRules = window.localStorage.getItem('rules');
    if (!encryptedRules) return 'No permissions'

    const rulesHashesArray: string[] = encryptedRules?.split(',').map(s => s.trim());

    const avaliablePermissions: string[] = ['MERCHANT_RESPONSIBLE']

    const searchPermissions = async (permissions: string[], hashes: string[]): Promise<string[]> => {
      const permissionsFounded: string[] = []

      for (const userPermission of permissions) {
        for (const permissionHashes of hashes) {
          const match = await compare(userPermission, permissionHashes)
          if (match) {
            permissionsFounded.push(userPermission);
            break;
          }
        }
      }
      return permissionsFounded;
    }
    return await searchPermissions(avaliablePermissions, rulesHashesArray)

  }
  public static getCodeVerifier() {
    return window.localStorage.getItem('codeVerifier');
  }
  public static setCodeVerifier(getCodeVerifier: string) {
    return window.localStorage.setItem('codeVerifier', getCodeVerifier);
  }
}